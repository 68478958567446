import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    margin-bottom: 15px;
  }
`;

export const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  button {
    margin-top: 20px;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    background-color: #999;
    color: #fff;
    cursor: pointer;
    drop-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
  }
`;

export const ImagePreview = styled.div`
  display: flex;
  flex: 0 0 30%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  margin-right: 1%;
  margin-bottom: 10px;
  padding: 15px;
  background-color: #eaeaea;
  flex-direction: column;

  img {
    min-height: 300px;
    max-height: 300px;
    max-width: 200px;
    margin-top: 10px;
  }

  select {
    width: 100%;
    padding: 5px;
  }

  @media only screen and (max-width: 768px) {
    flex: 1;
  }
`;

export const ImagePreviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  select{
    width: 80%;
  }

  span {
    cursor: pointer;
    text-align: center;
    background-color: #999;
    display: inline-block;
    text-align: center;
    padding: 13px 13px 0 13px;
    height: 44px;
  }

`

export const CameraContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  button {
    position: absolute;
    bottom: 12px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    background-color: #999;
    color: #fff;
    cursor: pointer;
    drop-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
  }

  @media only screen and (max-width: 768px) {
    height: 500px;
  }
`;

export const MaskCamera = styled.div`
  position: absolute;
  width: ${(props) => (props.$maskType === 'face' ? '50%' : '80%')};
  height: 80%;
  top: 20px;
  border: solid 3px rgba(255, 255, 255, 0.5); /* Ajuste a cor e a espessura da borda conforme necessário */
  box-sizing: border-box;
  border-radius: ${(props) => (props.$maskType === 'face' ? '50%' : '5px')};
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7);

  @media only screen and (max-width: 768px) {
    width: ${(props) => (props.$maskType === 'face' ? '80%' : '80%')};
    height: ${(props) => (props.$maskType === 'face' ? '80%' : '80%')};
  }
`;

export const CapturePhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const PictureTypeSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SelectionType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  width: 50%;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    background-color: ${(props) =>
    props.$layout.background ? props.$layout.background : '#672478'};
    color: #fff;

    svg {
      width: 100px;
      height: 70px;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 20px;
    }
  }
`;

export const ImageListNames = styled.ul`
      margin: 0;
      list-style: none;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
`

export const ImageLegendsContainer = styled.div`
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom:10px
`

export const ImageLegendsStatus = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      background: #F9F9F9;
      border-radius: .425rem;
      border: 1px solid #dddbdb;
      padding: 10px;


      @media (max-width: 768px) {
        flex-direction: column;
      }
`

export const TakePhotoButton = styled.button`
      left: 30%;
`

export const SwitchCameraButton = styled.button`
      right: 30%;
`

export const ImageLegendsStatusItem = styled.div`
      display: flex;
      align-items: center;
      padding-right: 15px;

      span {
        display: inline-block;
        background: ${props => props.color};
        width:20px;
        height: 20px;   
        border-radius: .425rem;  
        margin-right: 10px;    
      }

      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
`

export const ImageListNamesItem = styled.li`
      background: ${props => props.hasImage ? '#50cd89' : props.required ? '#f1416c' : '#DBDFE9'};
      color: ${props => props.required ? '#fff' : '#000'};
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 4px 8px;
      border-radius: .425rem;  
`

export const ImageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #009ef7;
  background: #f1faff;
  padding: 10px;
  margin-bottom: 20px;

  ol {
    margin: 0;
    list-style: none;

    li {
      margin-bottom: 10px;

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    margin: 0;
  }
}
`